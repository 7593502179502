/* global angular */
(function (angular) {
  'use strict';
  
  // MODULE DEFINITION
  angular.module('app.firebase', ['firebase'])
    .constant('FIREBASE_URL', 'https://lightbulb75.firebaseio.com')
  // .constant('FIREBASE_URL', 'https://dev-lightbulb75.firebaseio.com');
  ;

})(angular);